:root {
  --textColor: #b8331c;
  --bgColor: #fffbf4;
  --lightOrange: #ffb07f;
  --strongOrange: #ff6100;
}


.wrapper {
  max-width: 100%;
  margin: 1rem;
}

.detailsWrapper {
  display: flex;
  align-items: center;
  margin: 1rem .5rem;
}

.radio {
  position: relative;
  appearance: none;
  background-color: transparent;
  width: 1.5rem;
  height: 1.5rem;
  border: 0.15rem solid var(--strongOrange);
  border-radius: 50%;
  cursor: pointer;
}

.radio::before {
  position: absolute;
  top: 2px;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0 auto;
  content: "";
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  transform: scale(0);
  transition: all 120ms ease-in-out;
  box-shadow: inset 1rem 1rem var(--textColor);
}

.radio:checked::before {
  transform: scale(1);
}

.text {
  flex: 1;
  margin-right: 1rem;
  font-size: 1.2rem;
}

@media only screen and (min-width: 1025px) {
  .wrapper {
    width: 600px;
  }
}
