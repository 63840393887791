/* Fancy text for names and title only */
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap');
/* // <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 400 to 700

.dancing-script-<uniquifier> {
  font-family: "Dancing Script", cursive;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
} */

/* All other text */
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&display=swap');
/* // <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 400 to 800

.eb-garamond-<uniquifier> {
  font-family: "EB Garamond", serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
} */

:root {
  --textColor: #b8331c;
  --bgColor: #fffbf4;
  --lightOrange: #ffb07f;
  --strongOrange: #ff6100;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "EB Garamond", serif;
  font-style: normal;
}

body {
  background-color: #fffbf4;
}

h2 {
  font-family: "Dancing Script", cursive;
  font-style: normal;
}

form h2 {
  font-family: "EB Garamond", serif;
  font-style: normal;
}

/* LOADER ------- */
.loader {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: var(--lightOrange);
}

/* HOME------- */
.HomeContainer {
  position: relative;
  height: 100vh;
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  color: var(--textColor);
}

.homeImg {
  position: absolute;
  top: 40%;
  left: 20px;
  width: 800px;
  height: 400px;
}

.branchImg {
  position: absolute;
  top: -10%;
  left: 5%;
  width: 1000px;
}

.pumpkinImg {
  position: absolute;
  top: 40%;
  left: 55%;
  width: 1000px;
}

.homeImg img {
  width: 100%;
}

.branchImg img {
  width: 100%;
}

.pumpkinImg img {
  width: 100%;
}

/* BANNER */
.banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.couple {
  width: auto;
  font-size: 8rem;
  text-align: center;
  font-family: "Dancing Script", cursive;
  font-style: normal;
}

.amp {
  font-size: 6rem;
  font-family: "Dancing Script", cursive;
  font-style: normal;
}

.msg {
  font-size: 4rem;
  margin-bottom: 1rem;
  text-align: center;
}

.date {
  font-size: 6rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  z-index: 3;
}

.navLinksCont {
  width: 60%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  z-index: 3;
}

.homeLink {
  border: solid var(--textColor);
  height: 6rem;
  width: 20rem;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 3rem;
  color: var(--textColor);;
  cursor: pointer;
  transition: transform 200ms ease-in;
  /* clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%); */
}

.homeLink:hover {
  transform: scale(1.1);
}

/* Mobile */
@media only screen and (max-width: 430px) {
  .couple {
    width: 9rem;
    font-size: 4rem;
    padding-top: 3rem;
  }

  .amp {
    font-size: 2.5rem;
  }

  .msg {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .date {
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .navLinksCont {
    height: 7rem;
  }

  .homeLink {
    border: solid var(--textColor);
    height: 3rem;
    width: 10rem;
    font-size: 1.5rem;
  }

  .branchImg {
    position: absolute;
    top: -8rem;
    left: -21rem;
    width: 1000px;
  }

.pumpkinImg {
    position: absolute;
    top: 25.8rem;
    left: 3.5rem;
    width: 500px;
  }

  .details {
    height: 100vh;
  }
}

/* Detaisl text */
.details {
  position: relative;
  height: 110vh;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 3rem;
  overflow: hidden;
}

@media only screen and (max-width: 430px) {
  .details {
    height: auto;
  }
}

.details, h2, p {
  color: var(--textColor);;
}


.details-text {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.details-text h2 {
  font-size: 2.5rem;
  margin-bottom: .5rem;
  z-index: 5;
}

.details-text p {
  font-weight: 200;
  margin-bottom: .8rem;
  z-index: 5;
}

.fancy-text {
  font-size: 3.5rem;
}

.details-box-container {
  position: relative;
}

.kiteBox {
  position: absolute;
  width: 40rem;
  transform: rotate(90deg);
}

.kiteBox img {
  width: 100%;
}

.branchBox {
  position: absolute;
  top: -3rem;
  right: -13rem;
  width: 30rem;
  transform: scaleX(-1) rotate(-50deg);
  z-index: 4;
}

.branchBox img {
  width: 100%;
}

.pumpkinBox {
  position: absolute;
  bottom: -8rem;
  left: 16rem;
  width: 40rem;
  transform: scaleX(-1) rotate(10deg);
  z-index: 4;
  display: none;
}

.pumpkinBox img {
  width: 100%;
}

.info-img {
  height: 300px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(./assets/imgs/bridge.jpeg);
}

@media only screen and (min-width: 1025px) {
    .info-img {
      height: 400px;
      width: 600px;
      display: flex;
      align-self: center;
      margin-left: 2rem;
      margin-right: 2rem;
    }
  }

@media only screen and (min-width: 1024px) {
  .details {
    /* grid-template-columns: repeat(2, 1fr); */
    /* grid-template-rows: .3fr 1fr repeat(3, 0); */
    padding: 8rem 8rem;
    /* column-gap: 1rem; */
  }

  /* .details::before {
    position: absolute;
    content: '';
    height: 100%;
    width: 1200px;
    transform: translateX(0rem);
  } */

  /* .details-text { grid-area: 1 / 1 / 2 / 4; } */
  .form-container { grid-area: 2 / 1 / 3 / 2; }
  /* .venue { grid-area: 2 / 2 / 3 / 3; } */
  /* .map { grid-area: 2 / 3 / 3 / 4; } */

  .details-text h2 {
    font-size: 3.5rem;
  }

  .fancy-text {
    font-size: 4.5rem;
    z-index: 10;
  }

  .details-text p {
    font-size: 1.5rem;
  }
  .details-box-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .map {
    width: 50%;
    margin-left: .5rem;
    z-index: 3;
  }

  .venue {
    width: 50%;
    margin-right: .5rem;
    z-index: 3;
  }

  .kiteBox {
  position: absolute;
  width: 60rem;
  transform: rotate(90deg);
}

.kiteBox img {
  width: 100%;
}

.branchBox {
  position: absolute;
  top: -14rem;
  right: -26rem;
  width: 60rem;
  transform: scaleX(-1) rotate(10deg);
  z-index: 4;
}

.branchBox img {
  width: 100%;
}

.pumpkinBox {
  position: absolute;
  bottom: -8rem;
  left: 16rem;
  width: 40rem;
  transform: scaleX(-1) rotate(10deg);
  z-index: 4;
  display: block;
}
}

@media only screen and (min-width: 768px) {
  .details {
    height: 100vh;
    padding: 6rem 8rem;
  }
}

/* Venue */
.venue {
  text-align: center;
  margin-bottom: 1rem;
}

.slideTest {
  transform: translateY(0) !important;
}

.venue img {
  height: 200px;
  width: 100%;
  object-fit: cover;
}

.venue .name {
  color: var(--textColor);;
  font-weight: bold;
  font-size: 1.6rem;
  font-family: "Dancing Script", cursive;
  font-style: normal;
}

.venue p {
  position: relative;
  font-weight: 200;
  line-height: 1.4rem;
  font-size: 1.2rem;
  letter-spacing: 1px;
  margin-top: .5rem;
  z-index: 5;
}

.venue-name {
  font-weight: 400;
  color: var(--textColor);;
}

@media only screen and (min-width: 1024px) {
  .venue {
  text-align: left;
  }
  .venue img {
    height: 400px;
  }
  .venue .name {
    font-size: 2rem;
  }
  .venue p {
    line-height: 1.8rem;
    letter-spacing: 1.2px;
    font-size: 1.5rem;
  }
}

/* Footer */
.footer {
  position: relative;
  height: 10rem;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: .6rem;
  overflow: hidden;
  background-color: var(--lightOrange);
}

.footer p {
  position: absolute;
  bottom: 5px;
  color: var(--textColor);
  z-index: 3;
}

.pumpkin-family {
  position: absolute;
  top: -2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pumpkin-family img {
  width: 22rem;
}

.footer-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
