.container {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 1000ms ease-in;
}

.timer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 5rem;
}

.item {
  text-align: center;
}

.show {
  opacity: 1;
}

@media only screen and (max-width: 390px) {
  .timer {
    font-size: 3rem;
  }
}
