:root {
  --textColor: #b8331c;
  --bgColor: #fffbf4;
  --lightOrange: #ffb07f;
  --strongOrange: #ff6100;
}

.title {
  margin-left: 1rem;
}

.formContainer {
  position: relative;
  display: flex;
  flex-direction: column;
}

.formContainer, fieldset {
  margin-bottom: 1rem;
}

.btnWrapper {
  display: flex;
  width: 7rem;
  justify-content: space-between;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
}

.btn {
  height: 3.2rem;
  width: 3.2rem;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  color: var(--bgColor);
  background: var(--strongOrange);
  transition: all 1s ease-in-out;
  clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
}

.btn:disabled {
  background-color: #b3b1b1;
  cursor: auto;
}

@media only screen and (min-width: 1024px) {
  .formContainer {
    width: 80vw;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .formContainer, fieldset {
    margin-right: 1rem;
  }
}
