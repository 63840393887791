.container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 1rem;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-left: .5rem;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(112,122,122);
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: rgb(255, 255, 255, 0.6);
  transition: .2s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #98bae5;
}


input:checked + .slider:before {
  background-color: #fff;
  transform: translateX(26px);
}
