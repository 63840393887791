:root {
  --textColor: #b8331c;
  --bgColor: #fffbf4;
  --lightOrange: #ffb07f;
  --strongOrange: #ff6100;
}

.container {
  display: flex;
  align-items: center;
  margin-bottom: .5rem;
}

.labelWrapper {
  display: flex;
  flex-direction: column;
  margin-right: .6rem;
}

.primaryLabel {
  /* add styles if needed */
}

.secondaryLabel {
  font-size: .8rem;
  font-weight: 400;
  color: red;
}

/* new styles */
.input {
  position: relative;
  appearance: none;
  background-color: transparent;
  width: 1.5rem;
  height: 1.5rem;
  border: 0.15rem solid var(--strongOrange);
  border-radius: 50%;
  margin-right: .3rem;
}

.input::before {
  position: absolute;
  top: 2px;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0 auto;
  content: "";
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  transform: scale(0);
  transition: all 120ms ease-in-out;
  box-shadow: inset 1rem 1rem var(--textColor);
}

.input:checked::before {
  transform: scale(1);
}

.input:disabled {
  border: 0.15rem solid rgba(51, 51, 51, 0.5)
}
