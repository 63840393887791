.formWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

/* .formWrapper::before {
  position: absolute;
  content: '';
  height: 100%;
  width: 400px;
  inset: -32px 0px 48px 24px;
  z-index: -1;
  transform: translateX(-5rem);
  background: linear-gradient(#f4e7d6, #faf5f0);
} */

.barWrapper {
  display: flex;
}

@media only screen and (min-width: 1024px) {
  /* .formWrapper::before {
    width: 1300px;
  } */
}
