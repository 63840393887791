:root {
  --textColor: #b8331c;
  --bgColor: #fffbf4;
  --lightOrange: #ffb07f;
  --strongOrange: #ff6100;
}


.mainContainer {
  position: relative;
  height: 110vh;
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding-block: 2rem;
  overflow: hidden;
  color: var(--bgColor);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: url("../../assets/imgs/lift.jpeg");
  z-index: 7;
}

.overlay {
  position: absolute;
  top: 0;
  height: 110vh;
  width: 100vw;
  background: rgb(51, 51, 51, 0.3);
}

.timelineContainer {
  position: relative;
  height: 816px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timelineHead {
  font-size: 1.9rem;
  margin-bottom: 0.8rem;
  text-align: center;
  color: var(--bgColor);
  z-index: 2;
}

.btnWrapper {
  width: 300px;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  z-index: 2;
}

.timeLineBtn {
  width: 90px;
  font-size: 1.8rem;
  color: var(--bgColor);
  cursor: pointer;
  background-color: transparent;
}

.timeLineBtnActive {
  border: 2px solid var(--bgColor)!important;
  border-radius: 5px!important;
}

@media only screen and (min-width: 1024px) {
  .mainContainer {
    height: 60vh;
    justify-content: center;
  }

  .timelineContainer {
    max-height: 395px;
  }

  .timelineHead {
    font-size: 2.6rem;
  }
}
