.container {
  width: 45%;
  display: flex;
  flex-direction: column;
}

.label {
  font-weight: bold;
  margin-bottom: .3rem;
}

.input {
  height: 30px;
  border: none;
  border-radius: 5px;
  padding-left: .3rem;
  font-size: 1.4rem;
}
