:root {
  --textColor: #b8331c;
  --bgColor: #fffbf4;
  --lightOrange: #ffb07f;
  --strongOrange: #ff6100;
}

.guestWrapper {
  position: relative;
  padding: .5rem 1rem;
  width: 350px;
  margin: 0 1rem 1rem 1rem;
  border: solid var(--strongOrange);
}

.guest {
  text-transform: capitalize;
  font-size: 1.5rem;
  font-weight: 800;
  margin-left: 0.4rem;
  margin-right: 0.6rem;
  color: var(--strongOrange);
}

.btnWrapper {
  display: flex;
  width: 7rem;
  justify-content: space-between;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
}

.btn {
  height: 3rem;
  width: 3rem;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  color: var(--bgColor);
  background: var(--strongOrange);
  transition: all 1s ease-in-out;
}

.btn:disabled {
  background-color: #b3b1b1;
  cursor: auto;
}

@media only screen and (min-width: 1024px) {
  .guestWrapper {
    width: 600px;
  }
}

/* USER DETAILS */
.userDetailsWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

/* ATTENDANCE */
.attendenceWrapper {
  margin-bottom: 1rem;
}

.attendenceHeader {
  margin-bottom: .5rem;
}

.attendenceOpsWrapper {
  display: flex;
  flex-wrap: wrap;
}

/* DRINKS */
.drinksWrapper {
  margin-bottom: 1rem;
}

.drinksHeader {
  font-weight: 800;
  margin-bottom: .5rem;
}

.drinksOpsWrapper {
  display: flex;
  flex-wrap: wrap;
}

/* DIET */
.dietWrapper {
  margin-bottom: 1rem;
}

.dietHeader {
  margin-bottom: .5rem;
}

.dietOpsWrapper {
  display: flex;
}

/* ADD and REMOVE buttons */
.addGuest {
  height: 3rem;
  width: 3rem;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  margin-left: 1.5rem;
  color: var(--bgColor);
  background: var(--strongOrange);
  clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
}

.addGuestTxt {
  display: none;
  margin-right: .5rem;
}
.addGuestIcon {
  font-size: 2rem;
  margin-top: .2rem;
  cursor: pointer;
}

.wrapper {
  padding: 0.5rem 1rem;
}

@media only screen and (min-width: 1025px) {
  /* .addGuest {
    position: absolute;
    bottom: -4rem;
    left: 8rem;
  } */
}

.removeGuest {
  position: absolute;
  top: -11px;
  right: -2px;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
